import React, { useState } from "react";
import AuthService from "../services/auth.service";
import { useForm } from "react-hook-form";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeaderAd from "../components/ads/header-ad";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors },
  } = useForm();
  const [message, setMessage] = useState(null);
  const [hide, setHide] = useState(false);
  const [errorMessage] = useState("");

  const onSubmit = async (data) => {
    // console.log(data);

    const reply = await AuthService.requestPasswordToken(data.email);
    // console.log(reply);
    if (reply.status === "success") {
      setMessage(reply.message);
      setHide(true)
    }
  };

  return (
    <Layout>
      <Seo title={`Online Hangman`} />
      <div>
        <HeaderAd />
      </div>
      <div className={"container mx-auto px-4 pb-6 py-6"}>
        <h1 className={"text-4xl font-bold mb-10"}>Forgot your password?</h1>
        <div className={"w-full p-4  bg-white"}>
          {message && message}
          {hide !== true ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                <div className="mb-4">
                  <label
                    className="block text-grey-darker text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    name="email"
                    id="email"
                    type="email"
                    {...register("email", { required: true })}
                    placeholder="Enter your email address"
                  />
                </div>

                {errorMessage && (
                  <p className={"text-red-900 mb-4"}>{errorMessage}</p>
                )}
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-900 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
                    type="submit"
                  >
                    Request link to update password
                  </button>
                </div>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
